import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { v4 as uuid } from 'uuid'
import { db, storage } from '../Firebase'

const Input = () => {
  const [categories, setCategories] = useState([])
  const [enabled, setEnabled] = useState(true)
  const [title, setTitle] = useState('')
  const [category, setCategory] = useState('none')
  const [photos, setPhotos] = useState([])
  const [thumbnail, setThumbnail] = useState(null)

  useEffect(() => {
    db.collection("categories").get().then((snapshot) => {
      const newCategories = []
      snapshot.forEach(cat => newCategories.push(cat.data()))
      setCategories(newCategories)
    })
  }, [])
  
  const handleUpload = async () => {
    if (
      title === '' ||
      category === 'none' ||
      photos.length === 0 ||
      thumbnail === null
    ) {
      alert('Compila tutti i campi prima di procedere')
      return
    }

    setEnabled(false)
    // Carico le foto
    const photoPaths = []
    for (const file of photos) {
      const fileName = `${uuid()}.jpg`
      const photoRef = storage.ref().child(`${category}/${title}/${fileName}`)
      const snapshot = await photoRef.put(file)
      if (snapshot.state !== 'success') {
        alert('Caricamento fallito: ' + file.name)
        return
      } else {
        photoPaths.push(fileName)
      }
    }
    // Carico Thumbnail
    const thumbRef = storage.ref().child(`${category}/${title}/thumbnail.jpg`)
    const snapshot = await thumbRef.put(thumbnail)
    if (snapshot.state !== 'success') {
      alert('Caricamento fallito: ' + thumbnail.name)
      return
    }

    // Creo documento sul database
    try {
      await db.collection("photos").add({
        category,
        title,
        photos: photoPaths,
        order: 999
      })
    } catch (err) {
      console.error(err)
      alert('Caricamento fallito: ' + err)
    }
    
    alert('Caricamento effettuato!')
    setEnabled(true)
  }

  return (
    <Container>
      <h2>Input</h2>
      <h4>Title</h4>
      <p>
        <input type="text" disabled={!enabled} value={title} onChange={e => setTitle(e.target.value)} />
      </p>
      <h4>Category</h4>
      <select disabled={!enabled} value={category} onChange={e => setCategory(e.target.value)}>
        <option disabled={true} value="none">Option</option>
        {
          categories.map(c => <option key={c.value} value={c.value}>{c.name}</option>)
        }
      </select>
      <h4>Photos</h4>
      <p>
        <input type="file" disabled={!enabled} multiple={true} onChange={e => setPhotos(e.target.files)} />
      </p>
      <h4>Thumbnail Ratio 10/7</h4>
      <p>
        <input type="file" disabled={!enabled} onChange={e => setThumbnail(e.target.files[0])} />
      </p>
      <p>
        <input type="button" disabled={!enabled} onClick={handleUpload} value="upload" />
      </p>
    </Container>
  );
};

const Container = styled.main`
  position: relative;
  min-height: calc(100vh);
  overflow: hidden;
  display: block;
  top: 72px;
  padding: 0 calc(3.5vw + 5px);

  &:before {
    background: url("/images/home-background.png") center center / cover no-repeat fixed;
    content: "";
    position: absolute;
    inset: 0px;
    opacity: 1;
    z-index: -1;
  } 
`;

export default Input