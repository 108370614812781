import firebase from 'firebase'

const firebaseConfig = {
  apiKey: "AIzaSyA2rF6XEiRypTRSl8kZYM1oOIvn37wlS3A",
  authDomain: "andrea-breda.firebaseapp.com",
  projectId: "andrea-breda",
  storageBucket: "andrea-breda.appspot.com",
  messagingSenderId: "549941330080",
  appId: "1:549941330080:web:9b85f9eec5ea12555a09c5",
  measurementId: "G-WDCB1KMTFZ"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();
const storage = firebase.storage();

const getStorageUrl = fileID => `https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}/o/${fileID.replace(/\//g, '%2F')}?alt=media`

export { db, auth, provider, storage, getStorageUrl };
