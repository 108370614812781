import styled from "styled-components";
import React from "react";
import PropTypes from 'prop-types'
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectUserName, selectUserPhoto } from "../reducer/user";

const Header = ({ handleAuth, handleLogout, isAdmin }) => {
  const userName = useSelector(selectUserName);
  const userPhoto = useSelector(selectUserPhoto);

  return <Nav>
      <Logo>
        <Link to={`/`}>
          <img src='/images/logo.png' alt="Andrea Breda" />
        </Link>
      </Logo>
      <NavMenu>
            <Link to={'/'}>
              <img src="/images/home.png" alt="HOME" />
              <span>HOME</span>
            </Link>
            <Link to={'/biography'}>
              <img src="/images/bio.png" alt="BIO" />
              <span>BIO</span>
            </Link>
            <Link to={'/photos'}>
              <img src="/images/photo.png" alt="PHOTOS" />
              <span>PHOTOS</span>
            </Link>
            <Link to={'/contacts'}>
              <img src="/images/contacts.png" alt="CONTACT" />
              <span>CONTACT</span>
            </Link>            
            {
              isAdmin && <Link to={'/input'}>
                <span>INPUT</span>
              </Link>
            }
          </NavMenu>
          <NavMenuMobile>
          <Link>
              <img src="/images/menu.png" alt="MENU" />
              <span>MENU</span>
              <DropDown>
                <Link to={'/'}>
                <img src="/images/home.png" alt="HOME" />
                <span>HOME</span>
                </Link>
                <Link to={'/biography'}>
                  <img src="/images/bio.png" alt="BIO" />
                  <span>BIO</span>
                </Link>
                <Link to={'/photos'}>
                  <img src="/images/photo.png" alt="PHOTOS" />
                  <span>PHOTOS</span>
                </Link>
                <Link to={'/contacts'}>
                  <img src="/images/contacts.png" alt="CONTACT" />
                  <span>CONTACT</span>
                </Link>
              </DropDown>
            </Link>
          </NavMenuMobile>

      {
        !userName ?
        <Login onClick={handleAuth}>Login</Login>
        :
        <>
          
          <SignOut>
            <UserImg src={userPhoto} alt={userName} />
            <DropDownOut>
              <span onClick={handleLogout}>SIGN OUT</span>
            </DropDownOut>
          </SignOut>
        </>}
    </Nav>
}

Header.propTypes = {
  handleAuth: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool
}

const Nav = styled.nav`
 position: fixed;
 top: 0;
 left: 0;
 right: 0;
 height: 70px;
 background-color: #090b13;
 display: flex;
 justify-content: space-between;
 align-items: center;
 padding: 0 36px;
 letter-spacing: 16px;
 z-index: 3;
`
const Logo = styled.div`
 padding: 0;
 width: 70px;
 max-height: 70px;
 font-size: 0;
 display: inline-block; 
 
 img {
   display: block;
   width: 100%;
 }
`

const NavMenu = styled.div`
 align-items: center;
 display: flex;
 flex-flow: row nowrap;
 height: 100%;
 justify-content: flex-end;
 margin: 0;
 padding: 0;
 position: relative;
 margin-right: auto;
 margin-left: 25px;

 a {
   display: flex;
   align-items: center;
   padding: 0 12px;
  
    img {
      height: 20px;
      min-width: 20px;
      width: 20px;
      z-index: auto;
      margin-right: 3px;
      }

    span {
      color: rgb(249, 249, 249);
      font-size: 13px;
      letter-spacing: 1.42px;
      line-heigth: 1.08px;
      padding: 2px 0;
      white-space: nowrap;
      position: relative;
    
      &:before {
        background-color: rgb(249, 249, 249);
        border-radius: 0px 0px 4px 4px;
        bottom: -6px;
        content: " ";
        opacity: 0;
        left: 0px;
        height: 2px;
        position: absolute;
        right: 0px;
        transform-origin: left center;
        transform: scaleX(0);
        transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
        visibility: hidden;
        width: auto;
      }
    }
  
    &:hover {
      span:before {
        transform: scaleX(1);
        visibility: visible;
        opacity: 1 !important;
      }
    }
 }

 @media (max-width: 768px) {
   display: none;
 }
`

const Login = styled.a`
 background-color: rgba(0, 0, 0, 0.6);
 padding: 8px 16px;
 letter-spacing: 1.5px;
 border: 1px solid #f9f9f9;
 border-radius: 4px;
 transition: all 0.2s ease 0s;

 &:hover {
   background-color: #f9f9f9;
   color: #000;
   border-color: transparent
 }
`

const UserImg = styled.img`
 height: 100%;
`

const DropDown = styled.div`
  position: absolute;
  top: 48px;
  background: rgb(19, 19, 19);
  border: 1px solid rgba(151, 151, 151, 0.34);
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 50%) 0px 0px 18px 0px;
  padding: 10px;
  font-size: 14px;
  letter-spacing: 3px;
  opacity: 0;
  width: 150px;
  left: calc(50% - 75px);
  transform: scaleY(0);
  transition: all 0s none;
  visibility: hidden;
`;
const NavMenuMobile = styled.div`
 align-items: center;
 display: none;
 flex-flow: row nowrap;
 height: 100%;
 justify-content: center;
 margin: 0;
 padding: 0;
 position: relative;
 margin-right: auto;
 margin-left: 25px;
 width: 100%;

 a {
   display: flex;
   align-items: center;
   padding: 8px 12px;
  
    img {
      height: 20px;
      min-width: 20px;
      width: 20px;
      z-index: auto;
      margin-right: 3px;
      }

    span {
      color: rgb(249, 249, 249);
      font-size: 13px;
      letter-spacing: 1.42px;
      line-heigth: 1.08px;
      padding: 2px 0;
      white-space: nowrap;
      position: relative;
    }
  
    &:hover {      
      ${DropDown} {
        opacity: 1;
        transform: scaleY(1);
        visibility: visible;
      }
    } 
 }

 @media (max-width: 768px) {
   display: flex;
 }
`

const DropDownOut = styled.div`
  position: absolute;
  top: 48px;
  right: 0px;
  background: rgb(19, 19, 19);
  border: 1px solid rgba(151, 151, 151, 0.34);
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 50%) 0px 0px 18px 0px;
  padding: 5px;
  font-size: 14px;
  letter-spacing: 3px;
  width: 105px;
  opacity: 0;
`

const SignOut = styled.div`
  width: 48px;
  padding: 0;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  ${UserImg} {
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
  &:hover {
    ${DropDownOut} {
      opacity: 1;
      transition-duration: 1s;
    }
  }
`;

export default Header
