import React from "react";
import styled from "styled-components";
import ImgSlider from "./ImgSlider";
import Categories from "./Categories";

const Home = () => {  
   return (
      <Container>
        <ImgSlider />
        <Categories />
      </Container>
   )
};

const Container = styled.main`
  position: relative;
  min-height: calc(100vh);
  overflow: hidden;
  display: block;
  top: 72px;
  padding: 0 calc(3.5vw + 5px);

  &:after {
    background: url("/images/home-background.png") center center / cover no-repeat fixed;
    content: "";
    position: absolute;
    inset: 0px;
    opacity: 1;
    z-index: -2;
  } 
`;

export default Home
