import styled from "styled-components";
import React from "react";

const Contact = () => {
  return (
    <Container>
    <h3>Contact</h3>
        <div >
            <div>Email:</div>
            <div><a href="mailto:andreabreda1990@gmail.com">andreabreda1990@gmail.com</a></div>
        </div>
        <br/>
        <div>
            <div>Phone:</div>
            <div><a href="tel:+393283383059">+39 328 33 83 059</a></div>
        </div>
        <br/>
        <div>
            <div>Instagram:</div>
            <div><a href="https://www.instagram.com/andreabreda90">andreabreda90</a></div>
        </div>
        <br/>
        <div>
            <div>Facebook:</div>
            <div><a href="https://fb.com/andreabreda1990">fb.com/andreabreda1990</a></div>
        </div>    
    </Container>
  );
};

const Container = styled.main`
  position: relative;
  min-height: calc(100vh);
  overflow: hidden;
  display: block;
  top: 72px;
  padding: 0 calc(3.5vw + 5px);

  &:before {
    background: url("/images/home-background.png") center center / cover no-repeat fixed;
    content: "";
    position: absolute;
    inset: 0px;
    opacity: 1;
    z-index: -2;
  } 

  img {
    width: 20%;
    padding-top: 20px;
  }
`;

export default Contact