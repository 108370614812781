import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import { db, getStorageUrl } from '../Firebase'

const Category = () => {
  const { category } = useParams()
  const [categoryData, setCategoryData] = useState({ name: 'Loading...' })
  const [photos, setPhotos] = useState([])

  useEffect(() => {
    db.collection("categories")
      .where("value", "==", category)
      .get()
      .then(snapshot => {
        snapshot.forEach(c => setCategoryData(c.data()))
      })
    db.collection("photos")
      .where("category", "==", category)
      .orderBy("order", "asc")
      .get()
      .then(snapshot => {
        const dbPhotos = {}
        snapshot.forEach(c => dbPhotos[c.id] = c.data())
        setPhotos(dbPhotos)
      })
  }, [])

  return (
    <Container>
      <h4>{categoryData.name}</h4>
      <Content>
        {
          Object.keys(photos).map(photoID => {
            const photo = photos[photoID]
            return <div key={photoID}>
            <Wrap >
              {photo.id}
              <Link to={`/category/${category}/${photoID}`}>
                <img src={getStorageUrl(`${category}/${photo.title}/thumbnail.jpg`)} alt={photo.title} />
              </Link>
            </Wrap>
            <PhotoTitle>{photo.title}</PhotoTitle>
          </div>
          })
        }
      </Content>
    </Container>
  );
};

const Content = styled.div`
  display: grid;
  grid-gap: 25px;
  gap: 25px;
  grid-template-columns: repeat(4, minmax(0, 1fr));

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`
const Wrap = styled.div`
  padding-top: 56.25%;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
    rgb(0 0 0 / 73%) 0px 16px 10px -10px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  border: 3px solid rgba(249, 249, 249, 0.1);

  img {
    inset: 0px;
    display: block;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    position: absolute;
    transition: opacity 500ms ease-in-out 0s;
    z-index: 1;
    top: 0;
    width: 100%;
  }

  &:hover {
    box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
      rgb(0 0 0 / 72%) 0px 30px 22px -10px;
    transform: scale(1.05);
    border-color: rgba(249, 249, 249, 0.8);
  }
`

const Container = styled.main`
  position: relative;
  min-height: calc(100vh);
  overflow: hidden;
  display: block;
  top: 72px;
  padding: 0 calc(3.5vw + 5px);

  &:after {
    background: url("/images/home-background.png") center center / cover no-repeat fixed;
    content: "";
    position: absolute;
    inset: 0px;
    opacity: 1;
    z-index: -2;
  } 
`;


const PhotoTitle = styled.h2`
  text-align: center;
  text-shadow: 0 0 5px rgb(0 0 0 / 69%);
`
export default Category