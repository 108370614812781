import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import ImgsViewer from "react-images-viewer"

import { db, getStorageUrl } from "../Firebase";

const Detail = () => {
  const { photoid } = useParams();
  const [photo, setPhoto] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [hasThumbs, setThumbs] = useState(true);

  useEffect(() => {
    db.collection("photos")
      .doc(photoid)
      .get()
      .then(doc => {
        if (!doc.exists) return
        setPhoto(doc.data());
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }, [photoid]);

  if (!photo) return null

  const nextImage = () => {
    let next = currentImage + 1
    if (next > photo.photos.length - 1) next = 0
    setCurrentImage(next)
  }

  const previousImage = () => {
    let next = currentImage - 1
    if (next < 0) next = photo.photos.length - 1
    setCurrentImage(next)
  }

  return (
    <Container>
      <Background>
        <img alt={photo.title} src='/images/home-background.png' />
      </Background>

      <ImageTitle>
        <img alt={photo.title} src={getStorageUrl(`${photo.category}/${photo.title}/thumbnail.jpg`)} />
      </ImageTitle>
      <ContentMeta>
        <Controls>
          <Player onClick={() => setOpen(true)}>
            <img src="/images/play-icon-black.png" alt="" />
            <span>Play</span>
          </Player>
        </Controls>
        <Title>{photo.title}</Title>
      </ContentMeta>
      <ImgsViewer
        imgs={photo.photos.map(p => ({ src: getStorageUrl(`${photo.category}/${photo.title}/${p}`) }))}
        onClose={() => setOpen(false)}
        isOpen={isOpen}

        backdropCloseable={true}
        width='100%'

        showThumbnails={hasThumbs}
        showCloseBtn={hasThumbs}
        showImgCount={hasThumbs}

        currImg={currentImage}
        onClickThumbnail={i => setCurrentImage(i)}
        onClickImg={() => setThumbs(!hasThumbs)}
        onClickNext={nextImage}
        onClickPrev={previousImage}
      />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  min-height: calc(100vh);
  overflow-x: hidden;
  display: block;
  top: 72px;
  padding: 0 calc(3.5vw + 5px);
`;

const Background = styled.div`
  left: 0px;
  opacity: 0.8;
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: -1;
  img {
    width: 100vw;
    height: 100vh;
    @media (max-width: 768px) {
      width: initial;
    }
  }
`;

const ImageTitle = styled.div`
  align-items: flex-end;
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  margin: 0px auto;
  height: 30vw;
  min-height: 170px;
  padding-bottom: 24px;
  width: 100%;
  img {
    max-width: 600px;
    min-width: 200px;
    width: 35vw;
  }
`;

const ContentMeta = styled.div`
  max-width: 874px;
`;

const Controls = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  margin: 24px 0px;
  min-height: 56px;
`;

const Player = styled.button`
  font-size: 15px;
  margin: 0px 22px 0px 0px;
  padding: 0px 24px;
  height: 56px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 1.8px;
  text-align: center;
  text-transform: uppercase;
  background: rgb(249, 249, 249);
  border: none;
  color: rgb(0, 0, 0);
  box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
    rgb(0 0 0 / 73%) 0px 16px 10px -10px;

  img {
    width: 32px;
  }

  &:hover {
    background: rgb(198, 198, 198);
    box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
      rgb(0 0 0 / 72%) 0px 30px 22px -10px;
    transform: scale(1.05);
    border-color: rgba(249, 249, 249, 0.8);
  }
  
  @media (max-width: 768px) {
    height: 45px;
    padding: 0px 12px;
    font-size: 12px;
    margin: 0px 10px 0px 0px;
    img {
      width: 25px;
    }
  }
`;

const Title = styled.div`
  color: rgb(249, 249, 249);
  font-size: 28px;
  min-height: 20px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export default Detail;