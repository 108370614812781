import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from "react-redux";
import { auth, provider } from "./Firebase";
import { useDispatch } from "react-redux";

import { setUserLoginDetails, setSignOutState } from "./reducer/user";

import Header from './components/Header';
import Home from './components/Home';
import Detail from './components/Detail';
import Category from './components/Category';
import Photos from './components/Photos';
import Biography from './components/Biography';
import Input from './components/Input';
import Contact from './components/Contact';

import './App.css';

function App() {
  const dispatch = useDispatch();
  const u = useSelector(state => state.user)
  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) setUser(user);
    });
  }, [u]);
  
  const setUser = (user) => {
    dispatch(
      setUserLoginDetails({
        id: user.uid,
        name: user.displayName,
        email: user.email,
        photo: user.photoURL,
      })
    );
  };

  const handleAuth = () => {
    auth.signInWithPopup(provider)
      .then(setUser)
      .catch(console.error)  
  };

  const handleLogout = () => {
    auth.signOut()
    .then(() => dispatch(setSignOutState()))
    .catch(console.error)
  }
  
  const isAdmin = [
    'XDuqtKjsIuU92irCNjlD8aLgjNo1', // andrea
    'XZq91XBHsdSEAnvRnwHNuKhkvKG3', // dario
  ].includes(u.id)
  
  return <Router>
    <Header handleAuth={handleAuth} handleLogout={handleLogout} isAdmin={isAdmin} />
    <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/category/:category">
          <Category />
        </Route>
        <Route path="/category/:category/:photoid">
          <Detail />
        </Route>
        <Route path="/biography">
          <Biography />
        </Route>
        <Route path="/photos">
          <Photos />
        </Route>
        <Route path="/contacts">
          <Contact />
        </Route>
        {
          isAdmin
            ? 
              <Route path="/input">
                <Input />
              </Route>
              : <Redirect to="/" />
        }
        <Redirect to="/" />
      </Switch>
   </Router>
}

export default App;
