import styled from "styled-components";
import React from "react";

const Biography = () => {
  return (
    <Container>
      <Grid>
        <h4>Biography</h4>
        <img src="/images/me.jpg" />
      </Grid>
        <p><strong>Italiano</strong><br /><br />
        Andrea Breda, fotografo di origini pescaresi inizio ad appassionarmi alla fotografia all’età di 20 anni, quando con una vecchia reflex mi approccio per la prima volta  alla fotografia paesaggistica con i luoghi della mia terra e della mia infanzia.</p>
        <p>
        Durante un viaggio in Australia mi sono avvicinato alla fotografia paesaggistica immortalando persone e momenti che hanno caratterizzato la mia esperienza in luogo lontano da casa. Ho capito che grazie ad uno scatto, un sguardo, un sentimento potevano essere catturati dentro l’obiettivo senza subire nessuna mutazione esterna.</p>
        <p>
        {"Tornato in Italia decido di trasformare la mia passione nel mio lavoro, lascio la mia terra alla volta di Milano e mi  iscrivo all'Istituto Italiano di Fotografia dove completo la mia formazione professionale."}</p>
        <p>
        La mia curiosità mi ha portato a sperimentare la fotografia nelle sue mille sfaccettature, avvicinandomi anche al nuovo mondo dello scatto posato. A conclusione del mio percorso formativo,  mi specializzo sulla fotografia di moda, still life e ritrattistica.</p>
        <br />
        <p><strong>English</strong><br /><br />
        {"My name is Andrea Breda and I am a photographer, born and raised in Pescara, Italy. My passion in photography began at the age of 20, when I learned to capture my childhood adventures through landscape photography."}</p>
        <p>
        {"During a trip to Australia, my interest shifted to the culture of the people who lived there;  with these moments, my creativity increased even more. I realized that with a click of the camera, I could capture a look or even a feeling with my lens, candidly and without alteration."}</p>
        <p>
        Back in Italy, I decided to turn my passion for photography into a full time job. I left my home for Milan, where I enrolled in the Italian Institute of Photography and completed my professional photography training.</p>
        <p>
        My curiosity led me to explore the many facets of photography. I also began to approach the new world of posed photography. I now specialize in fashion, still life, and portraiture photography.
        </p>
    </Container>
  );
};

const Container = styled.main`
  position: relative;
  min-height: calc(100vh);
  overflow: hidden;
  display: block;
  top: 72px;
  padding: 0 calc(3.5vw + 5px);

  &:before {
    background: url("/images/home-background.png") center center / cover no-repeat fixed;
    content: "";
    position: absolute;
    inset: 0px;
    opacity: 1;
    z-index: -1;
  } 

  img {
    width: 20%;
    margin-top: 20px;

    border-radius: 10px;
    box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
                rgb(0 0 0 / 73%) 0px 16px 10px -10px;
    
    transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    border: 3px solid rgba(249, 249, 249, 0.1);

    &:hover {
      box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
        rgb(0 0 0 / 72%) 0px 30px 22px -10px;
      transform: scale(1.05);
      border-color: rgba(249, 249, 249, 0.8);
    }
  }
`;

const Grid = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export default Biography